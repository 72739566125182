<template>
  <portal>
    <div class="gallery-mobile-component">
      <div class="gallery-mobile-component__header">
        <div class="gallery-mobile-component__close" @click="close">
          <img src="~/assets/img/svg/common/close.svg"/>
        </div>
      </div>
      <div
        ref="refImages"
        class="gallery-mobile-component__images"
      >
        <suspense>
          <nuxt-img
            v-for="(item, index) in images"
            :key="`index-${ index }`"
            :src="String(item)"
            width="640"
            fit="contain"
            loading="lazy"
            @load="(event) => eventLoadImage(item, index, event)"
          />
        </suspense>
      </div>
    </div>
  </portal>
</template>

<script setup lang="ts">
import useGalleryComponent from '~/composables/useGallery';
import type { ComputedRef } from 'vue';

const galleryComponent = useGalleryComponent();
const images: ComputedRef<any[]> = computed(() => {
  return galleryComponent?.galleryState?.value?.imagesIds || []
});

const close = () => {
  galleryComponent.closeGallery();
};

const refImages: any = ref(null);
const eventLoadImage = (item: any, index: number, event: any) => {
  // if ((galleryComponent?.galleryState?.value?.indexActiveSlide || 0) <= 0) {
  //   return
  // }
  // if ((galleryComponent?.galleryState?.value?.indexActiveSlide || 0) !== index) {
  //   return;
  // }
  //
  // const boundingClientRect = event.target.getBoundingClientRect();
  // const topPosition = boundingClientRect.top - boundingClientRect.height - 10;
  // refImages.value.scrollBy({
  //   top: topPosition,
  //   behavior:"smooth"
  // });
  // console.log('boundingClientRect.top - boundingClientRect.height - 10: ', boundingClientRect.top - boundingClientRect.height - 10);
  // console.log('event: ', boundingClientRect);
};

onMounted(() => {
  document.body.style.overflow = 'hidden';
});
onBeforeUnmount(() => {
  document.body.style.overflow = '';
});
</script>

<style lang="scss">
.gallery-mobile-component {
  position: fixed;
  z-index: 999;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background: white;
}
.gallery-mobile-component__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  position: absolute;
  top: 0; left: 0; right: 0;
  background: white;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
}
.gallery-mobile-component__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  img {
    //filter: invert(1);
  }
}
.gallery-mobile-component__images {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-top: 50px;
  box-sizing: border-box;
  overflow: auto;
  height: 100%;
}
</style>
